/* eslint-disable */ 
import {
  StyleSheet,
  css
} from 'aphrodite';
import { AppTheme } from '../styles/AppTheme';
import { ColourPalette } from '../styles/AppColourPalette';

//TODO: move to styles folder
export const Styles = StyleSheet.create({
  headerOrgOuterContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  headerOrgOuterContainerSpec: {
    flex: 1,
    '@media (max-width: 767px)': {
      marginTop: 0,
    }
  },
  selectIcon: {
    // color: AppTheme.white
    color:"#fff",

  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow:"0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%)",
    background: '#fff !important',
    marginTop: '1rem'
  },
  editContent: {
    cursor: 'pointer',
    width: 30,
    height: 30,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ':hover': {
      // backgroundColor: AppTheme.secondaryColor
      background:"rgba(0, 0, 0, 0.07)"
    }
  },
  editContentSettings: {
    width: 40,
    height: 40,
  },
  innerTableContent: {
    backgroundColor: '#202633',
    padding: '22px 25px',
    margin: '10px 0 0 0',
    display: 'flex',
    gap: '12px',
    cursor: 'pointer',
    '@media(max-width: 767px)': {
      padding: '22px 10px',
    }
  },
  innerTableContentDisableClick: {
    // backgroundColor: '#202633',
    backgroundColor: '#fff',
    // border:"1px solid",
    padding: '15px 25px',
    // margin: '10px 0 0 0',
    display: 'flex',
    '@media(max-width: 767px)': {
      padding: '15px 10px',
    },
    fontSize:"0.9em !important"
  },
  buttonStyle: {
    marginRight: 8,
    // backgroundColor: ColourPalette.color3,
    backgroundColor:AppTheme.primaryBase,
    borderRadius: 4,
    color: AppTheme.white,
    fontWeight: 'bold',
    fontSize: 11,
    maxHeight: AppTheme.actionHeaderbarHeight
  },
  buttonStyleUpload: {
    marginRight: 0,
    marginLeft: 20,
    height: 39,
    fontSize: 12,
    '@media (max-width: 767px)': {
      marginLeft: 0,
    }
  },
  innerSearchContainer: {
    border: '1px solid #354058',
    borderRadius: 4,
    width: '12rem',
  },
  paginationContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '1rem'
  },
  link: {
    textDecoration: 'none',
  },
  orgDetailContainer: {
    display: 'flex',
    '@media(max-width: 767px)': {
      flexDirection: 'column'
    }
  },
  orgDetailInnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: .5,
    background:"#fff",
    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)'
  },
  orgDetailInnerContainerSecondary: {
    marginLeft: 15,
    '@media(max-width: 767px)': {
      marginLeft: 0,
      marginTop: 10
    }
  },
  orgDetailContainerTilte: {
    display: 'flex',
    // backgroundColor: AppTheme.primaryBackground,
    // border: '1px solid',
    padding: 15,
    borderRadius: '8px 8px 0px 0px',
    borderBottom:"1px solid rgba(0, 0, 0, 0.12)"
  },
  orgDetailContainerTiltContent: {
    margin: 0,
    // color: AppTheme.white,
    color:"#000",
    // opacity: 0.6
  },
  orgDetailInnerContent: {
    // backgroundColor: '#202633',
    backgroundColor:"none",
    // border:"1px solid",
    display: 'flex',
    padding: 15,
    borderRadius: '0px 0px 8px 8px',
    flex: 1
  },
  imgOuterContainer: {
    height: 100,
    width: 100,
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #d3d3d347 !important',
    display: 'flex',
    borderRadius: 5,
    marginRight: 15
  },
  ImgContent: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  overrallContainer: {
    marginLeft: 35
  },
  overrallContainerTitle: {
    margin: 0,
    opacity: 0.6,
    // color: AppTheme.white,
    fontSize: 11,
    fontWeight: 'bold',
  },
  overrallContainerContent: {
    // color: '#2CB673',
    fontSize: 48,
    // fontWeight: 300,
    margin: 0,
  },
  overrallContainerContentSecond: {
    // color: '#c5c52b'
  },
  overrallContainerContentThird: {
    // color: 'orange'
  },
  menuHeaderRoleConatiner: {
    backgroundColor: ColourPalette.color1,
    marginRight: 5,
    borderRadius: 5,
    padding: 4,
    display: 'flex',
    alignItems: 'center',
    '@media(max-width: 767px)': {
      marginTop: 5
    }
  },
  menuHeaderRole: {
    margin: 0,
    color: AppTheme.primaryBackground,
    // fontSize: 13,
    // fontWeight: 'bold'
  },
  gridImg: {
    marginRight: 0
  },
  formControlSwitch: {
    width: 55,
    margin: '0px 15px'
  },
  sliderContainer: {
    width: 55,
    padding: 0,
    height: 4
  },
  defaultIcon: {
    fontSize: 100,
    color: AppTheme.primaryBase
  },
  contactContainer: {
    display: 'flex',
    marginTop: 10,
  },
  contact: {
    margin: 0,
    marginLeft: 10,
    color: 'white',
    opacity: 0.6
  },
  liveTitleContainer: {
    backgroundColor: '#2CB673',
    borderRadius: 4,
    marginLeft: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 13,
    color:"#000",
    width: 50,
    padding: 8,
    fontWeight: 'bold'
  },
  liveTitleDotContainer: {
    width: 4,
    height: 4,
    backgroundColor: AppTheme.white,
    borderRadius: '50%',
    marginLeft: 6
  },
  pickerInnerContainer: {
    color: AppTheme.white,
    marginRight: 10,
  },
  pickerInnerContainer2: {
    color: "#000",
    marginRight: 10,
  },
  selectOrgMenu: {
    minWidth: 150,
    maxWidth: 150,
    // backgroundColor: AppTheme.secondaryColor,
    boxShadow:"rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px !important",
    color: "#fff",
    fontSize: 15,
    padding: 6,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    // border: '1px solid ' + AppTheme.secondaryColor,
    ':hover': {
      // border: '1px solid ' + ColourPalette.color3
    },
    '@media(max-width: 768px)': {
      minWidth: 'unset',
      paddingRight: '25px'
    }
  },
  tableInnerContent: {
    '@media(max-width: 767px)': {
      marginRight: 0
    }
  },
  titleAndSummaryStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '@media (max-width: 767px)': {
      display: 'flex',
      flex: 1,
      //marginBottom: '10px',
    }
  },
  settingsIcon: {
    // color: AppTheme.white,
    color: "#fff",
    fontSize: 25, //TODO
  },
  advancedSettingsIcon: {
    color: AppTheme.white,
    fontSize: 20, //TODO
  },
  healthyIcon: {
    color: '#30f064',
    fontSize: 18,
    marginRight: 10,
  },
  unHealthyIcon: {
    color: '#f04a4a',
    fontSize: 18,
    marginRight: 10,
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  }
})