/* eslint-disable */ 
import React, { Component } from 'react';
import {
  css
} from 'aphrodite';
import { Link } from 'react-router-dom';
import { Styles } from './Styles'
import { CommonStyles } from '../../styles/Styles';
import 'rc-slider/assets/index.css';
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { CheckboxComponent } from '../../components/CheckboxComponent';
import { DateRangePicker } from './DateRangePicker';
import { SingleDatePicker } from './SingleDatePicker';
import { EventFilter } from './EventFilter'
import { EventRefreshController } from './EventRefreshController'
import { Toggler } from '../../common/components/Toggler';
import { EventDownloadReport } from './EventDownloadReport';
import { ViewSummaryButton } from './ViewSummaryButton';
import { ViewChartButton } from './ViewChartButton';
import { SingleSelect } from '../../components/SingleSelect';
import { AppConfig } from '../../AppConfig';
import { Button } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';


export class EventActionHeaderbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  renderBackBtn = () => {
    if (this.props.backPathName) {
      return (
        <Link
          to={{ pathname: this.props.backPathName }}
          className={css(Styles.link)}>
          <div className={css(CommonStyles.headerMemberAddContainer, CommonStyles.backButton)} >
            <img src={require('../../assets/images/back-img.svg')} className={css(CommonStyles.addImg)} />
            <p className={css(CommonStyles.margin)}>BACK</p>
          </div>
        </Link>
      )
    }
  }


  render() {
    return (
      <div>
        <div className={this.props.relativeImageSliderMode ? css(CommonStyles.outerHeaderContainer, Styles.withOpacity, this.props.fullScreenMode == "EXIT FULLSCREEN" ? CommonStyles.headerFixedTop : '') : css(CommonStyles.outerHeaderContainer, this.props.fullScreenMode == "EXIT FULLSCREEN" ? CommonStyles.headerFixedTop : '')}>
          <div className={css(CommonStyles.innerHeaderContainer, CommonStyles.innerHeaderContainerCustom)}>
            <div className={css(CommonStyles.innerHeaderFirstContainer, CommonStyles.innerHeaderFirstContainerCustom)}   style={{padding:"1.2rem 1rem", border:"1px solid white"}}>
              {this.renderBackBtn()}
              {(this.props.breadcrumbs || this.props.title) &&
                <div className={css(CommonStyles.headerOrgOuterContainer, Styles.headerOrgOuterContainer)}>
                  <p className={css(CommonStyles.headerMemberCountContainer)}>{this.props.title}</p>
                  {this.props.breadcrumbs &&
                    <Breadcrumbs items={this.props.breadcrumbs} />
                  }
                </div>
              }

              {this.props.placePicker &&
                <CheckboxComponent
                  headerView={true}
                  formField={this.props.placePicker}
                  value={this.props.selectedPlaceIds}
                  setValue={this.props.placeSelectHandler}
                  disabled={false} />
              }

              {this.props.camPicker &&
                <CheckboxComponent
                  headerView={true}
                  formField={this.props.camPicker}
                  value={this.props.selectedCamIds}
                  setValue={this.props.camSelectHandler}
                  disabled={false}
                />
              }

              {
                this.props.singlePicker &&
                <SingleSelect
                  headerView={true}
                  formField={this.props.singlePicker}
                  value={this.props.selectedCamIds}
                  setValue={this.props.singlePickerHandler}
                  disabled={false}
                />
              }

              {!this.props.hideDateRangePicker &&
                <DateRangePicker
                  timeChangeHandler={this.props.timeChangeHandler}
                  fromDateTs={this.props.fromDateTs}
                  toDateTs={this.props.toDateTs}
                />
              }

              {
                this.props.enableReportType &&
                <>
                  <FormControl className={css(Styles.inputCss)}>
                    {/* <InputLabel
                      htmlFor="report-type-select"

                     classes={{
                      root: css(Styles.inputLabelRoot),
                      focused: css(Styles.inputLabelFocused)
                    }}
                    >Select Report Type</InputLabel> */}
                    <SingleSelect
                      id="report-type-select"
                      headerView={true}
                      formField={{
                        "options": [
                          {
                            key: "generic",
                            value: "generic",
                            name: "Generic Report",
                            _id: "generic",
                          },
                          {
                            key: "fr",
                            value: "fr",
                            name: "FR Report",
                            _id: "fr",
                          }
                        ]
                      }}
                      value={this.props.reportType}
                      setValue={this.props.reportTypeHandler}
                      disabled={false}
                    />
                  </FormControl>
                </>
              }

              {this.props.singleDatePicker &&
                <SingleDatePicker
                  timeChangeHandler={this.props.timeChangeHandler}
                  fromDateTs={this.props.fromDateTs}
                  toDateTs={this.props.toDateTs}
                />
              }

              {!this.props.hideFilter &&
                <EventFilter
                  showApplyFilters={this.props.showApplyFilters}
                  priorityFilters={this.props.priorityFilters}
                  tagFilters={this.props.tagFilters}
                  filterTagValue={this.props.filterTagValue}
                  filterHandler={this.props.filterHandler}
                  place={this.props.place}
                />
              }
              {
                this.props.enableHeatMap &&
                <div className={css(Styles.eventItemInnerContent)}>
                    {
                      <Button
                        variant='contained'
                        onClick={() => this.props.showHeatMap()}
                        className={css(Styles.buttonStyle2)}
                      >
                         Heatmap
                      </Button>
                    }
                </div>
              }


              {/* {this.props.onGroupedDisplayChange &&
                <Toggler
                  defaultMode={true}
                  title={'GROUP'}
                  toolTip={'GROUPED DISPLAY'}
                  onChange={this.props.onGroupedDisplayChange}
                />
              } */}

              {this.props.showApplyFilters &&
                <Button
                  style={{ marginLeft: "0.7rem" }}
                  variant='contained'
                  className={css(Styles.buttonStyle)}
                  onClick={() => this.props.applyFilters()}
                >
                  APPLY
                </Button>
              }

              {/* {
                this.props.enableJob &&
                <div className={css(Styles.eventItemInnerContent)}>
                  {
                    <Button
                      style={{ marginLeft: "0.7rem" }}

                      variant='contained'
                      onClick={() => this.props.createJob()}
                      className={css(Styles.buttonStyle)}
                    >
                      CREATE JOB
                    </Button>
                  }
                </div>
              }

              {
                this.props.enableJob &&
                <div className={css(Styles.eventItemInnerContent)}>
                  {
                    <Button
                      style={{ marginLeft: "0.7rem" }}

                      variant='contained'
                      onClick={() => this.props.showJobs()}
                      className={css(Styles.buttonStyle)}
                    >
                      SHOW JOBS
                    </Button>
                  }
                </div>
              } */}

            </div>

          
            <div className={css(Styles.headerMemberOuterContainer)}>

              {this.props.showDownload && AppConfig.ENABLE_REPORT_DOWNLOAD &&
                <EventDownloadReport
                  downloadReport={() => this.props.downloadReport()}
                />
              }

              {this.props.showSummaryButton &&
                (this.props.selectedCamIds && this.props.selectedCamIds.length < 2) &&
                <ViewSummaryButton
                  showStatsHandler={this.props.showStatsHandler}
                  showStats={this.props.showStats}
                />
              }

              {this.props.showChartButton &&
                <ViewChartButton
                  showChart={this.props.showChart}
                  showChartHandler={this.props.showChartHandler}
                />
              }

              {this.props.eventRefreshHandler &&
                <EventRefreshController
                  eventRefreshHandler={this.props.eventRefreshHandler}
                />
              }


            </div>

          </div>
        </div>
      </div>
    )
  }

}
