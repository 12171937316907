import React, { Component, Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite';
import async from 'async';
import { UiHelper } from '../common/helpers/UiHelper';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { Helper } from '../common/helpers/Helper';
import { ActionHeaderbar } from '../components/ActionHeaderbar';
import { SettingsComponent } from '../components/SettingsComponent';
import { CommonStyles } from '../styles/Styles';
import { Controller } from '../common/Controller';
import { AppConfig } from '../AppConfig';
import { SimpleTabs } from '../components/Tab';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { Button, Input, Grid } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { PopOverV2 } from '../components/PopOverV2';
import { appData } from '../data/AppData';
import { adminData } from '../data/AdminData.js';
import { CustomFormFieldsDialogV2 } from '../components/CustomFormFieldsDialogV2';

export class Settings extends Component {
  constructor(props) {
    super(props);
    this.responseByKey = {}
    this.orgId = this.props.match.params.id ? this.props.match.params.id : null;
    this.orgListView = (this.props.location && this.props.location.state) ? this.props.location.state.orgListView : null
    this.state = {
      fetchState: ApiHelper.State.LOADING,
      showWFH:true,	
      showOffice:false,
      userNotificationList:[],
      showUserNotificationDialog:false,
      dialogTitle:"Add a user",
      dialogAction:'',
      enableFR : true,
      enlargedImage:false,
      imageId:-1,
      query:"",
      output:"",
      anchorEl:null,
      selectedItem:null
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.fetchItems();
  }

  showAddUserNotificationDIalog = () =>{
    this.setState({
      showUserNotificationDialog : true,
      dialogTitle:"Add a user",
      dialogAction:'add'
    })
  }

  addFace=(obj)=>{
    this.setState({
      inProcess: true
    })

    if(obj._id){
        this.updateFace()
      }else{
      this.saveFace()
    }
  }

  updateFace = () => {
    const id = this.state.selectedItem ? this.state.selectedItem._id : null
    if (!id) {
      return
    }

    const thisObj = this;
    // let camId = thisObj.camId
    let placeId = this.placeId
    let options = {};
    let data = thisObj.responseByKey
    // data['camId'] = camId
    data['placeId'] = placeId
    data['camId'] = "100c465e"

    if (data['alertEnabled']!=undefined) {
      delete data['alertEnabled']
    }
    if (this.validateFace(data)) {

      options = {
        method: 'PUT',
        endPoint: ApiHelper.makeUrlPath(['faces', id]),
        jsonBody: data
      }

      Helper.removeNullValues(options.jsonBody)

      ApiHelper.call(
        options,
        function (err, data) {
          if (err) {
            this.setState({
              errMsg: Helper.getErrorMsg(err),
              inProcess: false,
            })
            return;
          } else {
            this.resetToDefaultValues()
            this.fetchNotificationUsers()
          }
        }.bind(this)
      );
    }
  };

  fetchNotificationUsers = () => {
    const thisObj = this;
    this.setState({
      fetchState: ApiHelper.State.LOADING
    })
    async.parallel(
      [
        function (callback) {
          ApiHelper.call(
            { method: 'GET', endPoint: ApiHelper.makeUrlPath(['faces'], { orgId: thisObj.orgId }) }, callback)
        },
      ],
      function (err, results) {
        if (err) {
          this.setState({
            fetchState: ApiHelper.State.ERROR,
            errMsg: Helper.getErrorMsg(err)
          })
          return
        }
        this.setState({
          userNotificationList:results[0]? results[0].sort((a, b) => a.name.localeCompare(b.name)):[],
          fetchState: ApiHelper.State.READY,
          // enableFR : this.state.org && this.state.org.settings && this.state.org.settings.webcam1 && this.state.org.settings.webcam1.webcam1_unauthorizedFace == "YES" ? true:false
        })
      }.bind(this))
  }

  resetToDefaultValues = () => {
    this.responseByKey = {}
    this.setState({
      showUserNotificationDialog: false,
      inProcess: false,
      dialogAction: '',
      dialogTitle: '',
      selectedItem: {},
      anchorEl: null,
      confirmDeleteView: false,
      errMsg: null
    })
  }


  editClick = (e, item) => {
    if (!e) {
      const e = window.event;
      e.cancelBubble = true;
    }
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    this.setState({
      anchorEl: e.currentTarget,
      selectedItem: item
    })
  }

  editClickClose = () => {
    this.setState({ anchorEl: null })
  }

  enlargeImage = (index) =>{
    this.setState({
      enlargedImage : !this.state.enlargedImage,
      imageId: index
    })
  }


  validateFace = (data) => {
    if (!data.name) {
      this.setState({
        errMsg: "Please enter name",
        inProcess: false,
      })
      return false

    } else if (!data.type) {
      this.setState({
        errMsg: "Please enter type",
        inProcess: false,
      })
      return false

    } else if (!data.image || data.image.length == 0) {
      this.setState({
        errMsg: "Please upload images",
        inProcess: false,
      })
      return false

    }
    return true
  }

  saveFace = () => {
    const thisObj = this;
    let orgId = thisObj.orgId
    // let placeId = this.placeId
    let options = {};
    let data = thisObj.responseByKey
    data['orgId'] = orgId
    data['camId'] = "100c465e"
    if (data['alertEnabled'] != undefined) {
      delete data['alertEnabled']
    }
    if(this.validateFace(data)){
    options = {
      method: "POST",
      endPoint: ApiHelper.makeUrlPath(["faces"]),
      jsonBody: { faceData: data }
    };
    ApiHelper.call(
      options,
      function (err, data) {
        if (err) {
          this.setState({
            errMsg: Helper.getErrorMsg(err),
            inProcess: false,
          })
          return;
        } else {
          this.resetToDefaultValues()
          this.fetchNotificationUsers()
        }
      }.bind(this)
    );
  }
  };

  enableFRChange = () =>{
    this.setState({
      enableFR: !this.state.enableFR
    }, () => {
    let orgData = {
      enableFR : this.state.enableFR,
      enableUnauthorize:true
    }
    const options = {
      method: 'PUT',
      endPoint: ApiHelper.makeUrlPath(['orgs', this.state.org._id]),
      jsonBody: {
        orgData: orgData
      }
      
    }

    Helper.removeNullValues(options.jsonBody.orgData)

    ApiHelper.call(options, function (err, data) {
      if (err) {
        this.setState({
          respMsg: Helper.getErrorMsg(err),
          inProcess: false,
        })
        return
      }

      let org = this.state.org
      org.settings.webcam1.webcam1_unauthorizedFace = this.state.enableFR ? "YES":"NO"

      this.setState({
        respMsg: Helper.getString('settingsUpdated'),
        inProcess: false,
        org :org
      })

    }.bind(this))
  })
  }


  userTable = () => {
    return (
      <>
        <div className={css(Styles.frMainContainer)}>
          {/* {this.state.org.type == 'alert'  && this.state.showOffice &&
            <FormControlLabel
              color='secondary'
              className={css(Styles.formControlLabelContainer)}
              label={"Face Training"}
              labelPlacement={'start'}
              control={
                <Switch
                  classes={{
                    track: this.state.enableFR && css(Styles.switchControlLabeltrack),
                    checked: css(Styles.switchControlLabelChecked),
                    thumb: css(Styles.switchControlLabelThumb),
                  }}
                  checked={this.state.enableFR}
                  onChange={this.enableFRChange}
                />
              }
            />
          } */}
          { this.state.org.type == 'alert'  && this.state.showOffice &&
            <div className={css(Styles.shiftRight)}>
              <Button
                // disabled={this.state.inProcess || !this.state.enableFR}
                variant='contained'
                onClick={() => this.showAddUserNotificationDIalog()}
                className={css(Styles.buttonStyle)}>
                ADD USER
              </Button>
            </div>

          }
        </div>
        {this.state.enableFR &&
          <Table className={css(Styles.tableOuterContainer)}>
            <TableHead className={css(Styles.tableHead)}>
              <TableRow className={css(Styles.tableRowHeader)}>
                <TableCell
                  align='center'
                  className={css(Styles.tableHeader)}>
                  <p>SL.No</p>
                </TableCell>


                {adminData.userNotificationFields.map((user, i) => {
                  return (
                    <TableCell key={i}
                      align='center'
                      className={css(Styles.tableHeader)}>
                      {user.name}
                    </TableCell>
                  )
                })}

              </TableRow>
            </TableHead>

            <TableBody>

              {this.state.userNotificationList && this.state.userNotificationList.length > 0 && this.state.userNotificationList.map((item, index) => {
                return (<TableRow key={index} className={css(Styles.tableRow)}>
                  <TableCell className={css(Styles.tableItem)}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      {index + 1}
                    </div>
                  </TableCell>
                  <TableCell align='center' className={css(Styles.tableItem)}>{item.name}</TableCell>
                  <TableCell align='center' className={css(Styles.tableItem)}>{item.tag ? item.tag.join(",") : "Tag not added"}</TableCell>
                  {/* <TableCell align='center' className={css(Styles.tableItem)}>{item.type && item.type.toLowerCase() == 'blacklisted' ? 'BLOCKED' : item.type && item.type.toLowerCase() == 'whitelisted' ? 'AUTHORIZED' : item.type}</TableCell> */}
                  <TableCell onClick={() => this.enlargeImage(item._id)} align='center' className={css(Styles.tableItem)}><img
                    style={{
                      overflow: 'hidden',
                      transition: 'transform 0.3s',
                      transform: this.state.enlargedImage && this.state.imageId == item._id && item.image ? 'scale(6)' : 'scale(1)',
                      position: "relative",
                      zIndex: this.state.enlargedImage && this.state.imageId == item._id ? 10000 : 'unset',
                      objectFit: 'scale-down'

                    }}
                    alt='no image found' height='50px' width='50px' src={item.image[0]} /> {Array.isArray(item.image) && item.image.length > 1 ? <span>+ {item.image.length - 1}</span> : ""}</TableCell>
                  <TableCell align='center' className={css(Styles.tableItem, Styles.prewrap)}>
                    <Fragment>
                      <PopOverV2
                        id={index}
                        boxShadowLight={true}
                        open={Boolean(this.state.anchorEl)}
                        items={appData.popOverFields}
                        anchorEl={this.state.anchorEl}
                        onPopOverClick={this.onPopOverClick}
                        editClickClose={this.editClickClose}
                      />  </Fragment>
                    <div className={css(Styles.editContainer)}>
                      <div onClick={(e) => this.editClick(e, item)} className={css(Styles.editContent)} >
                        <img src={require('../assets/images/more-rounded.svg')} />
                      </div>
                    </div>
                  </TableCell>
                </TableRow>)
              })}

            </TableBody>
          </Table>
        }
      </>

    )
  }

  onPopOverClick = (key) => {
    if (key === 'edit') {
      this.showUpdateUserDialog()
    } else if (key === 'delete') {
      this.showDeleteUserDialog()
    }
  }

    
  showDeleteUserDialog = () => {
    this.responseByKey = this.state.selectedItem
    this.setState({
      anchorEl: null,
      showUserNotificationDialog: true,
      dialogTitle: 'Delete a user',
      dialogAction: 'delete',
    })
  }

  showUpdateUserDialog = () => {
    this.responseByKey = this.state.selectedItem
    // this.oldObj = JSON.parse(JSON.stringify(this.state.selectedItem))
    this.setState({
      anchorEl: null,
      showUserNotificationDialog: true,
      dialogTitle: 'Edit a user',
      dialogAction: 'edit',
    })
  }

  fetchItems = () => {
    const thisObj = this;

    if (!Controller.get().userMgr().isAdminUser() && !Controller.get().userMgr().isProductUser()){
      this.setState({
        fetchState: ApiHelper.State.ERROR,
        errMsg: Helper.getString('permissionErr')
      })
      return
    }

    async.parallel(
      [
        function (callback) {
          ApiHelper.call(
            { method: 'GET', endPoint: ApiHelper.makeUrlPath(['orgs', thisObj.orgId]) }, callback)
        },
      ],
      function (err, results) {
        if (err) {
          this.setState({
            fetchState: ApiHelper.State.ERROR,
            errMsg: Helper.getErrorMsg(err)
          })
          return
        }

        for (const f of AppConfig.orgTypes) {
          if (f.key === results[0].type) {
            this.data = f
            break;
          }
        }
        
        if(!results[0]['officeSettings']){
          results[0]['officeSettings']= {['settings'] : {}}
        }
        this.setState({
          org: results[0],
          fetchState: ApiHelper.State.READY,
        }, ()=>{
          if (AppConfig.ENABLE_FR_SETTINGS) {
            this.fetchNotificationUsers()
          }
        })
      }.bind(this))
  }

  deleteUser = () => {
    const id = this.state.selectedItem ? this.state.selectedItem._id : null
    if (!id) {
      return
    }

    this.setState({
      inProcess: true
    })

    const options = {
      method: 'DELETE',
      endPoint: ApiHelper.makeUrlPath(['faces', id]),
    }

    ApiHelper.call(options, function (err, data) {
      if (err) {
        this.setState({
          errMsg: Helper.getErrorMsg(err),
          inProcess: false,
        })
        return
      } else {
        this.resetToDefaultValues()
        this.fetchNotificationUsers()
      }
    }.bind(this))
  }

  renderUserNotificationDialog = () => {
    const formFields = appData.userNotificationFields
    return (
      <CustomFormFieldsDialogV2

        onClose={this.resetToDefaultValues}
        dialogOpen={this.state.showUserNotificationDialog}
        dialogTitle={this.state.dialogTitle}
        confirmDeleteView={this.state.confirmDeleteView}
        // errMsg={this.state.errMsg}
        inProcess={this.state.inProcess}
        action={this.state.dialogAction}
        formFields={formFields}
        showConfirmDeleteView={this.showConfirmDeleteView}
        multi={true}
        deleteItem={() => this.deleteUser()}
        responseByKey={this.responseByKey}
        // enableValidation={this.state.dialogAction === 'delete' ? false : true}
        errMsg={this.state.errMsg}
        onSuccess={this.addFace}
      // formDisabled={this.state.dialogAction === 'delete' ? true : false}

      >
      </CustomFormFieldsDialogV2>
    )
  }

  showConfirmDeleteView = () => {
    this.setState({
      confirmDeleteView: true,
      inProcess: false,
    });
  };

  render() {
    if (this.state.fetchState === ApiHelper.State.LOADING) {
      return UiHelper.componentLoadingView();
    } else if (this.state.fetchState === ApiHelper.State.ERROR) {
      return UiHelper.errorView(this);
    } else {
      return this.readyView();
    }
  }

  handleTabPanelChange = (value) =>{	
    if(value == 0){	
      this.setState({	
        showWFH : true,	
        showOffice :false	
      })	
    }else{	
      this.setState({	
        showWFH :false,	
        showOffice :true	
      })	
    }	
  }	

  readyView = () => {
    return (
      <div className={css(CommonStyles.outerMainContainer)}>
        <ActionHeaderbar
          backPathName={this.orgListView ? '/orgs' : '/orgs/' + this.orgId}
          statSuffix={'FEATURES & SETTINGS'}
          title={this.state.org.name}
        />
        <div className={css(CommonStyles.innerMainContainer)}>
          <div className={css(Styles.innerMainContent)}>
            {this.state.org.type == 'wfh' && <SimpleTabs numberOfTabs={['Work From Home', 'Work From Office']} handleTabPanelChange={this.handleTabPanelChange}></SimpleTabs>}
            {(this.state.org.type == 'alert' || this.state.org.type == 'eevms') && <SimpleTabs  tabVal={this.state.showOffice == true ? 1 : 0 } numberOfTabs={['Account Settings', 'Face Recognition']} handleTabPanelChange={this.handleTabPanelChange}></SimpleTabs>}

            {this.data && this.data.features && this.state.showWFH &&
              <SettingsComponent
                data={this.data}
                item={this.state.org}
                itemId={this.orgId}
                itemName={'orgs'}
                apiBodyPrefix={'orgData'}
              />
            }
            {	
              this.state.showOffice && this.state.org.type != 'alert' &&          	
             <SettingsComponent	
              data={this.data}	
              item={this.state.org.officeSettings}	
              itemId={this.orgId}	
              itemName={'orgs'}	
              apiBodyPrefix={'orgData'}	
              showOffice = {this.state.showOffice}	
            />	
            }
            {(this.state.org.type == 'alert' || this.state.org.type == 'eevms') && this.state.showOffice &&
              this.userTable()
            }
            {this.state.enableFR  && this.state.showOffice && this.renderUserNotificationDialog()}

            {!this.data &&
              < p className={css(Styles.emptyDataContainer)}>{Helper.getString('noItemsFound')}</p>
            }
          </div>
        </div>
      </div>
    )
  }
}


const Styles = StyleSheet.create({
  innerMainContent: {
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
    margin: '40px 0px 50px 0px',
    '@media(max-width: 768px)': {
      width: '90%',
    }
  },
  emptyDataContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    flex: 1,
  },
  formControlLabelContainer: {
    margin: '1rem 0px !important'
  },
  switchControlLabel: {
    color: '#377bac',
    margin: 0,
    fontSize: 14,
    opacity: 0.6
  },
  switchControlLabelChecked: {
    padding: 10,
    color: '#377bac',
  },
  switchControlLabelThumb: {
    width: 18,
    height: 18
  },
  switchControlLabeltrack: {
    backgroundColor: '#377bac'
  },
  frMainContainer:{
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop:'1rem'
  }
})
