import React, { Component } from 'react';
import { css } from 'aphrodite';
import 'rc-slider/assets/index.css';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import { Styles } from './Styles'
import { CommonStyles } from '../styles/Styles';

export class SingleSelect extends Component {
  constructor(props) {
    super(props);
    this.initialSelectedCamIds = this.props.selectedCamIds
    this.state = {
      value: this.props.value  || this.props.formField.defaultValue
      // || this.props.formField.defaultValue,
    }
  }

  componentDidMount(){
    if(!this.props.value){
      this.setState({ value: this.props.formField.defaultValue});
      this.props.setValue(this.props.formField.defaultValue)
    }else{
      this.setState({ value: this.props.value});
      this.props.setValue(this.props.value)
    }
  }

  change = (event, item) => {
    this.setState({
      value: event.target.value
    });
    this.props.setValue(event.target.value)
  }

  render() {
    return (
      <FormControl className={css(this.props.singlePickerForm)}>
        <Select
          value={this.state.value}
          className={css(this.props.timezone ? Styles.pickerInnerContainer2 : Styles.pickerInnerContainer)}
          // style={this.props.style && this.props.timezone?this.props.style:''}
          onChange={(e, item) => this.change(e, item)}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            getContentAnchorEl: null,
            classes: {
              paper: css(CommonStyles.menuItemContainerRoot),
              list: css(CommonStyles.menuItemListContainerRoot)
            }
          }}
          input={
            <Input
              disableUnderline={true}
            />
          }
          classes={{
            selectMenu: css(Styles.selectOrgMenu),
            icon: css(Styles.selectIcon),
          }}
        >
          {this.props.formField.options.map(item => {
            return (
              <MenuItem
                value={item._id}
                key={item._id}
                className={css(CommonStyles.menuItemContainer)}>
                <p className={css(CommonStyles.margin, this.props.timezone ? CommonStyles.bgBlack:'')}>{item.name}</p>
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    )
  }
}
