/* eslint-disable */ 
import React, { Component } from 'react';
import { CheckboxComponent } from './CheckboxComponent';
import { TextInputComponent } from './TextInputComponent';
import { SelectComponentV2 } from './SelectComponentV2';
import { ImageUploaderComponent } from './ImageUploaderComponent';

import {
  AppTheme,
  AppStyles
} from '../styles/AppTheme';
import { ColourPalette } from '../styles/AppColourPalette';
import {
  StyleSheet,
  css
} from 'aphrodite';
import { ImageUploader } from '../common/components/ImageUploader';

export class FormFields extends Component {

  constructor(props) {
    super(props);
    this.responseByKey = this.props.responseByKey || {}

    this.labelByAction = {
      lookup: 'NEXT',
      delete: 'DELETE',
      add: 'SAVE',
      edit: 'SAVE',
      upload: 'UPLOAD'
    }
    this.state = {
      dummy: true //TODO
    }
  }

  setValue = (value, key) => {
    this.props.setValue(value, key, this.props.parentKey)
    this.setState({
      dummy: !this.state.dummy
    })
  }

  checkParentResponseByKey = (formField) =>{
    if(!this.props || !this.props.responseByKey )return true
    // return this.props.responseByKey[formField.show.key] != formField.show.value
    return formField.show.value.includes(this.props.responseByKey[formField.show.key]) == false
  }

  getFormFieldContent = (formField) => {
    if (formField.type === 'input_text') {
      if (this.props.action === 'viewInfo' && formField.key === 'logoUrl') {
        return null
      } else {
        return (
          <TextInputComponent
            inputCss={{ width: '100%' }}
            formField={formField}
            hidden = {formField.show && formField.show.value && formField.show.key && this.checkParentResponseByKey(formField)}
            value={this.responseByKey[formField.key]}
            enableValidation={this.props.enableValidation}
            setValue={this.setValue}
            disabled={this.props.formDisabled || formField.disabled}
            action={this.props.action}
          />
        )
      }
    } else if (formField.type === 'checkbox') {
      return (
        <CheckboxComponent
          inputCss={{ width: '100%' }}
          formField={formField}
          value={this.responseByKey[formField.key] ? this.responseByKey[formField.key] : []}
          enableValidation={this.props.enableValidation}
          setValue={this.setValue.bind(this)}
          disabled={this.props.formDisabled}
          action={this.props.action}
        />
      )
    } else if (formField.type === 'select_single') {
      return (
        <SelectComponentV2
          inputCss={{ width: '100%' }}
          formField={formField}
          value={this.responseByKey[formField.key]}
          setValue={this.setValue}
          enableValidation={this.props.enableValidation}
          disabled={this.props.formDisabled || formField.disabled}
          action={this.props.action}
        />
      )
    } else if (formField.type === 'upload_image') {
      return (
        <ImageUploaderComponent
          inputCss={{ width: '100%' }}
          formField={formField}
          value={this.responseByKey[formField.key]}
          handleUploadSuccess={this.handleUploadSuccess.bind(this)}
        />
      )
    }
  }

  generateContent = () => {
    return (
      <div className={css(Styles.fieldsOuterContainer)}>
        <div className={css(Styles.fieldsOuterContainer)}>
          {this.props.formFields && this.props.formFields.map((formField, index) => {
            return (
              <div
                key={index}
                className={css(Styles.formFieldContainer)}>
                {formField.show && formField.show.value && formField.show.key && this.checkParentResponseByKey(formField) ? <></> : <div className={css(Styles.formFieldContainerSpec)}>
                  <h5 className={css(Styles.formFieldLabel)}>{formField.label}</h5>
                </div>}
                {formField.show && formField.show.value && formField.show.key && this.checkParentResponseByKey(formField) ? <></> : <div className={css(Styles.formFieldContainerSpec)}>
                  {this.getFormFieldContent(formField)}
                </div>}
              </div>
            )
          })}
        </div>
      </div>
    )
  }
  render() {
    this.responseByKey = this.props.responseByKey || {}
    return (
      <div className={css(Styles.fieldsContainer)}>
        {!this.props.confirmDeleteView &&
          <div className={this.props.action === 'viewInfo' ?
            css(Styles.fieldsInnerMainContainer) : css(Styles.fieldsInnerMainContainer, Styles.fieldsViewInnerMainContainer)}>
            {this.props.formFields.map(item => {
              return (
                (item.key === 'logoUrl' && this.props.action === 'viewInfo') &&
                <div className={css(Styles.fieldsInnerMainContent)}>
                  <p className={css(Styles.fieldsInnerLabelContainer)}>Site Image</p>
                  <div className={css(Styles.fieldsInnerImgContainer)}>
                    <img
                      src={this.responseByKey.logoUrl ? this.responseByKey.logoUrl : require('../assets/images/location-img.svg')}
                      onError={(e) => {
                        e.target.src = 'https://uv-misc-test.s3.ap-south-1.amazonaws.com/location-img.svg'
                      }}
                      className={css(Styles.fieldsInnerImgContent)} />
                  </div>
                </div>
              )
            })}
            <div className={css(Styles.fieldsInnerContainer)}>
              {this.props.formFields &&
                this.generateContent()
              }
            </div>
          </div>
        }
      </div>

    )
  }
}

const Styles = StyleSheet.create({
  dialog: {
    minWidth: 850,
    '@media(max-width: 768px)': {
      minWidth: '-webkit-fill-available',
      margin: 5
    }
  },
  dialogPaper: {
    margin: 0
  },
  scrollPaper: {
    justifyContent: 'center',
    '@media(max-width: 767px)': {
      overflow: 'scroll',
      justifyContent: 'flex-start'
    }
  },
  dialogTitle: {
    backgroundColor: AppTheme.white,
    padding: 20,
    '@media(max-width: 767px)': {
      padding: '20px 13px'
    }
  },
  dialogTitleContainer: {
    display: 'flex'
  },
  dialogTitleContent: {
    margin: 0,
    fontSize: AppTheme.fontSizeTitle,
    cursor: 'default',
    flex: 1,
  },
  closeIconContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    cursor: 'pointer'
  },
  dialogContent: {
    backgroundColor: AppTheme.primaryBackground,
    display: 'flex',
    justifyContent: 'center',
    padding: '25px 25px 0px',
    '@media(max-width: 767px)': {
      padding: '5px 5px 0px',
    }
  },
  fieldsContainer: {
    width: '100%',
  },
  fieldsInnerLabelContainer: {
    margin: 0,
    color: AppTheme.white,
    opacity: 0.6,
    fontSize: 12,
    marginBottom: 5
  },
  fieldsInnerMainContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  fieldsViewInnerMainContainer: {
    justifyContent: 'center'
  },
  fieldsInnerMainContent: {
    marginTop: 4,
    marginRight: 60
  },
  fieldsInnerImgContainer: {
    maxWidth: 180,
    maxHeight: 180
  },
  fieldsInnerImgContent: {
    width: '100%',
    height: '100%'
  },
  fieldsInnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '20px',
    width:'90%'
  },
  dialogActions: {
    backgroundColor: AppTheme.primaryBackground,
    display: 'flex',
    justifyContent: 'center',
    padding: 24,
    paddingTop: 0,
    marginTop: -5,
    '@media(max-width: 767px)': {
      padding: 5,
      paddingBottom: 20
    }
  },
  dialogActionsInnerContainer: {
    width: '100%',
    display: 'flex',
  },
  dialogActionsInnerContainerButton: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  deleteMsg: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    fontWeight: 'bold',
    color: AppTheme.white,
    fontSize: 15,
    cursor: 'defalut',
    marginLeft: 8
  },
  buttonStyle: {
    marginRight: 8,
    backgroundColor: ColourPalette.color3,
    borderRadius: 4,
    color: AppTheme.white,
    fontWeight: 'bold',
    fontSize: 11,
    padding: '5px 16px'
  },
  circulerProgress: {
    marginLeft: 10,
    color: AppTheme.white
  },
  deleteConfirmMsg: {
    color: AppTheme.white,
    cursor: 'default'
  },
  fieldsOuterContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  },
  fieldsInnerContainerOne: {
    display: 'flex'
  },
  imageUploaderContainer: {
    // backgroundColor: AppTheme.primaryBackground,
    margin: 8,
    background:"#eee",
    color:"#000",
  },
  formFieldLabel: {
    // color: AppTheme.white,
    color:"#000",
    fontSize: 16,
    fontWeight:"100 !important",
  },
  formFieldContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%'
  },
  formFieldContainerSpec: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '50%',
    maxWidth:"40rem"
  },
  inputCss: {
    width: '100%'
  }
})