/* eslint-disable */ 
import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import * as urlParser from 'url-parse';
import * as qs from 'qs';
import Tooltip from '@material-ui/core/Tooltip';
import { AppStyles } from '../styles/AppTheme';
import moment from 'moment';
import { AppConfig } from '../AppConfig';
import { Controller } from '../common/Controller';

export class SearchHelper {

  static filterItemsBySearchText = (searchTerm, items) => {
    const filteredItems = items.filter((e) => {
      const searchTermRegExp = new RegExp(searchTerm, 'gi');
      let inName = false, inDescription = false, inType = false, inTime = false, inPriority = false, intags = false, inbbox = false;
      if (e) {
        if (searchTerm &&
          (searchTerm.startsWith('p:') || searchTerm.startsWith('P:'))) {
          let searchTermPr = '';
          if (searchTerm.startsWith('p:')) {
            searchTermPr = searchTerm.replace('p:', '');
          } else if (searchTerm.startsWith('P:')) {
            searchTermPr = searchTerm.replace('P:', '');
          }
          const searchTermPrRegExp = new RegExp(searchTermPr, 'gi');
          inPriority = searchTermPrRegExp.test(String(e.priority));
        } else {
          if (e.name) {
            inName = searchTermRegExp.test(e.name);
          }
          if (e.tags) {
            intags = searchTermRegExp.test(JSON.stringify(e.tags));
          }
          if (e.description) {
            inDescription = searchTermRegExp.test(e.description);
          }

          if (e.type) {
            inType = searchTermRegExp.test(e.type);
          }

          if (e.event_time) {
            inTime = searchTermRegExp.test(moment(e.event_time).format('MMM DD, YYYY, h:mm:ss A'));
          }
          if (e.bbox) {
            inbbox = searchTermRegExp.test(JSON.stringify(e.bbox));
          }
        }
      }

      return (inName || inDescription || inType || inTime || inPriority || intags || inbbox);
    });
    return filteredItems
  }

  static filterItemsByTag = (tagFilters, filterTagValue, items) => {
    const filteredCamEvents = [];
    // for (const item of items) {

    //   let tagFlag = false;
    //   if(item.name && item.name.includes('logged in')){
    //   if(tagFilters.includes('Login') ){
       
    //       tagFlag = true
    //     }
    //   }

    //   if(item.name && item.name.includes('logged out')){
    //     if(tagFilters.includes('Logout')){
         
    //         tagFlag = true
    //       }
    //     }

    //   if (item.tags) {
    //     Object.keys(item.tags).map(tag => {
    //       const index = tagFilters.indexOf(tag);
    //       if (index > -1) {
    //         tagFlag = true
    //       }
    //     })


    //     Object.values(item.tags).map(tag => {
    //       const index = tagFilters.indexOf(tag);
    //       if (index > -1) {
    //         tagFlag = true
    //       }
    //     })
    //   }


    //   if (item.tags) {
    //     Object.values(item.tags).map(tag => {
    //       const regex = new RegExp(tagFilters.join("|"), "i"); // Combine tagFilters into a regex
    //       if (regex.test(tag)) { // Check if the tag matches the regex
    //         tagFlag = true;
    //       }
    //     });
    //   }
      
    //   if(item.genAI){
    //     const index = tagFilters.indexOf("genAI");
    //     if (index > -1) {
    //       tagFlag = true
    //     }
    //   }

    //   if (!tagFlag) {
    //     continue;
    //   }

    //   filteredCamEvents.push(item);
    // }

    for (const item of items) {
      let tagFlag = false;

      // if (item.name && item.name.includes('logged in') && tagFilters.includes('Login')) {
      //     tagFlag = true;
      // }

      // if (item.name && item.name.includes('logged out') && tagFilters.includes('Logout')) {
      //     tagFlag = true;
      // }

      if (item.tags) {
          // Object.keys(item.tags).forEach(tag => {
          //     if (tagFilters.includes(tag)) {
          //         tagFlag = true;
          //     }
          // });
          // const regex = new RegExp(tagFilters.join("|"), "i");

          // Object.values(item.tags).forEach(tag => {
          //     if (regex.test(tag)) {
          //         tagFlag = true;
          //     }
          // });
        let flatArr = tagFilters.flat()
        const regex = new RegExp(flatArr.join("|"), "i");
        tagFlag = Object.values(item.tags).some(tag => regex.test(tag));
      }

      if (item.genAI && tagFilters.includes("genAI")) {
          tagFlag = true;
      }

      if (tagFlag) {
          filteredCamEvents.push(item);
      }
  }

  return filteredCamEvents
    // items = [...filteredCamEvents]

    // if (filterTagValue && Object.keys(filterTagValue).length > 0) {
    //   const filteredCamEventsWithVal = items.filter((e) => {
    //     let inTag = false;
    //     if (e && e.tags) {
    //       Object.values(e.tags).map(tag => {
    //         const index = tagFilters.indexOf(tag);
    //         if (index > -1) {
    //           inTag = true
    //         }
    //       })

    //       Object.keys(filterTagValue).map(tagF => {
    //         if (filterTagValue[tagF] && filterTagValue[tagF].length > 0 && (e.tags[tagF] == filterTagValue[tagF])) {
    //           inTag = true
    //         }
    //       })
    //     }
    //     //console.log('inTag', inTag, e.name)
    //     return (inTag);
    //   })
    //   items = filteredCamEventsWithVal
    // }

    // return items
  }

  static filterItemsByPriority = (priorityFilters, items) => {
    const filteredItems = [];
    for (const item of items) {
      if (priorityFilters.length !== 0) {
        if (!priorityFilters.includes(item.priority)) {
          continue;
        }
      }

      filteredItems.push(item);
    }

    return filteredItems
  }
}