/* eslint-disable */ 
import { StyleSheet, css } from 'aphrodite';
import { AppTheme } from '../../styles/AppTheme';
import { ColourPalette } from '../../styles/AppColourPalette';


const innerEventListContainerWidth = '15%'
const eventItemOuterContainerWidth = '85%'
const eventItemHeaderContainerWidth = '70%'
const eventItemFeatureContainerWidth = '30%'
export const Styles = StyleSheet.create({
  bgBlack:{
    color:"#000"
  },
  headerOrgOuterContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'center',
    marginRight: 10
  },
  selectIcon: {
    // color: AppTheme.white
    color:"#000",

  },
  selectCheckbox: {
    color: AppTheme.white
  },
  buttonContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 5
  },
  buttonStyle: {
    marginRight: 8,
    backgroundColor: ColourPalette.color3,
    borderRadius: 4,
    color: AppTheme.white,
    fontWeight: 'bold',
    fontSize: 11,
    padding: '5px 16px'
  },
  buttonStyle2: {
    backgroundColor: ColourPalette.color3,
    borderRadius: 4,
    color: AppTheme.white,
    fontWeight: 'bold',
    fontSize: 11,
    padding: '5px 16px',
    marginLeft:'5px'
  },
  buttonStyleApply: {
    marginRight: 0,
    fontSize: 13,
  },
  ml0:{
    marginLeft:'0px'
  },
  buttonStyleUpload: {
    marginRight: 0,
    marginLeft: 20,
    height: 39,
    fontSize: 12
  },
  inputCssTextField: {
    flex: .6,
    // color: AppTheme.white
    color:"#000"
  },
  headerTextFieldContentInput: {
    borderRadius: 4,
    // backgroundColor: AppTheme.secondaryColor,
    paddingLeft: 8,
    // color: AppTheme.white,
    backgroundColor:"rgb(238, 238, 238) !important",
    color:"#000",
  },
  headerTextFieldContent: {
    // color: AppTheme.white,
    zIndex: 1,
    paddingLeft: 8,
    opacity: .6,
  },
  menuHeaderRoleConatiner: {
    backgroundColor: ColourPalette.color1,
    // color: AppTheme.primaryColor,
    marginRight: 10,
    borderRadius: 5,
    padding: 4,
    display: 'flex',
    alignItems: 'center',
  },
  menuHeaderRoleStyleConatiner: {
    backgroundColor: AppTheme.primaryColor,
    color: '#000'
  },
  menuHeaderRole: {
    margin: 0,
    // fontSize: 13,
    // fontWeight: 'bold',
    cursor: 'pointer'
  },
  tagFilterOuterContainer: {
    flex: 1,
    margin: '10px 0px'
  },
  tagFilterContainer: {
    display: 'flex'
  },
  headerTagContainer: {
    flex: .4,
    display: 'flex',
    justifyContent: 'flex-start'
  },
  gridImg: {
    marginRight: 0
  },
  formControlSwitch: {
    width: 55,
    margin: '0px 3px'
  },
  sliderContainer: {
    width: 55,
    padding: 0,
    height: 4
  },
  selectOrgMenu: {
    minWidth: 150,
    maxWidth: 150,
    backgroundColor: AppTheme.secondaryColor,
    color: AppTheme.white,
    fontSize: 15,
    padding: 7,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    border: '1px solid ' + AppTheme.secondaryColor,
    ':hover': {
      border: '1px solid ' + ColourPalette.color3
    },
    '@media(max-width: 768px)': {
      minWidth: 'unset'
    }
  },
  headerMemberAddContainerSecond: {
    justifyContent: 'flex-start',
    padding: 0,
    paddingLeft: 10,
    paddingRight: 10,
    '@media(max-width: 768px)': {
      marginTop: 10,
      paddingRight: 0,
      justifyContent: 'center',
    }
  },
  filterModeOn: {
    border: '1px solid #FF682A' //TODO Change color, Applied when any of the filter applied
  },
  headerMemberAddContainerSecondSpecific: {
    marginRight: 10,
    '@media(max-width: 768px)': {
      marginRight: 0
    }
  },
  headerMemberStyle: {
    justifyContent: 'flex-start',
    padding: 0,
    paddingLeft: 10,
    paddingRight: 10,
    marginRight: 10,
    '@media(max-width: 768px)': {
      marginTop: 10,
      marginRight: 0,
      //paddingRight: 0,
      justifyContent: 'center',
    }
  },
  headerMemberStyleSpec: {
    '@media(max-width: 768px)': {
      marginTop: 0,
    }
  },
  headerMemberMobileStyle: {
    '@media(max-width: 768px)': {
      display: 'none'
    }
  },
  dateContainer: {
    fontSize: 14,
    color: AppTheme.white,
    margin: 0,
    '@media(max-width: 768px)': {
      flex: 1
    }
  },
  pickerInnerContainer: {
    color: AppTheme.white,
    marginRight: 10,
    '@media (max-width: 768px)': {
      //marginTop: 10,
      marginRight: 0,
    }
  },
  input: {
    cursor: 'pointer'
  },
  dateControlImg: {
    opacity: .7,
    marginRight: 8
  },
  dateInputCss: {
    marginTop: 8,
    color: 'white'
  },
  timeInputCss: {
    marginLeft: 16,
    color: 'white',
    marginTop: 8,
    width: '70%',
    '@media (max-width: 768px)': {
      marginLeft: 0,
      width: '100%',
    }
  },
  dateControlLabel: {
    padding: 15,
    display: 'flex',
    flexDirection: 'column'
  },
  dateControlLabelSubContainer: {
    display: 'flex',
    '@media (max-width: 768px)': {
      flexDirection: 'column'
    }
  },
  dateControlLabelTimeSubContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  dateClose: {
    marginLeft: 5,
    display: 'flex',
    alignItems: 'center',
    width: 20
  },
  menuItemPopoverContainer: {
    minWidth: 300,
    marginTop: 5,
    '@media (max-width: 768px)': {
      minWidth: 'unset',
      width: '-webkit-fill-available'
    }
  },
  formControlLabelContainer: {
    margin: 0,
    height: AppTheme.actionHeaderbarHeight
  },
  switchControlLabelChecked: {
    padding: 10,
    // color: ColourPalette.color3,
  },
  switchControlLabelThumb: {
    width: 18,
    height: 18
  },
  switchControlLabeltrack: {
    // backgroundColor: ColourPalette.color3
  },
  switchControlLabel: {
    color: AppTheme.white,
    margin: 0,
    fontSize: 14,
    //opacity: 0.6
  },
  filterPopoverContainer: {
    // backgroundColor: AppTheme.primaryBackground,
    borderRadius: 4,
    maxWidth: 350,
    marginTop: 10,
    '@media (max-width: 767px)': {
      maxWidth: '91%'
    }
  },
  filterContainerHeader: {
    backgroundColor: AppTheme.white
  },
  filterContentHeader: {
    margin: 0,
    padding: 20,
    color: AppTheme.primaryBackground,
    fontSize: AppTheme.fontSizeTitle,
    cursor: 'default'
  },
  divider: {
    backgroundColor: '#626F8A'
  },
  saveFilterContainer: {
    fontSize: 13,
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    // opacity: 0.8
  },
  filterContent: {
    padding: 20
  },
  filterInnerContent: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 10
  },
  filterIncidentTitle: {
    opacity: 0.6,
    // color: AppTheme.white,
    fontSize: AppTheme.fontSizeContent,
    margin: 0,
    marginTop: 10,
    marginBottom: 5,
    cursor: 'default'
  },
  editAlertTag: {
    // flexDirection: 'column',
    flexDirection: 'row',
  },
  headerEditAlertContainer: {
    marginTop: 10,
  },
  headerEditAlertDotContainer: {
    borderRadius: '4px 0px 0px 4px'
  },
  headerEditAlertLabel: {
    borderRadius: '0px 0px 0px 0px'
  },

  link: {
    textDecoration: 'none'
  },
  innerContainer: {
    width: '100%',
    display: 'flex',
    // paddingTop: 20,
    padding:"1rem",
    flexDirection: 'column'
  },
  innerSubContainer: {
    display: 'flex',
    maxHeight: '830px',
    '@media(min-width: 1750px) or (max-width: 768px)': {
      maxHeight: 'unset',
    }
  },
  inputCss: {
    margin: 0,
    flex: 1,
    color:"#000",
    // color: AppTheme.white,
    width: '100%'
  },
  inputCssText: {
    margin: 0,
    flex: 0.5
  },
  textFieldContentInput: {
    borderRadius: 4,
    // backgroundColor: AppTheme.secondaryColor,
    // paddingLeft: 8,
    // color: AppTheme.white,
    backgroundColor:"rgb(238, 238, 238) !important",
    color:"#000",
    height: 30,
    padding: 5
  },
  textFieldContentInputText: {
    paddingLeft: 8
  },
  pl0: {
    padding: 0
  },
  editTextFieldContentInput: {
    borderRadius: 4,
    // color: AppTheme.white,
    color:"#000"
  },
  textFieldContent: {
    // color: AppTheme.white,
    zIndex: 1,
    opacity: .6
  },
  innerSearchContainer: {
    border: '1px solid #354058',
    borderRadius: 4,
    width: '85%',
  },
  innerSearchContent: {
    // color: AppTheme.white,
    fontSize: AppTheme.fontSizeContent,
    opacity: .6,
    cursor: 'default',
    margin: '10px 0px',
    marginTop: 0,
    textAlign: 'center'
  },
  eventListTopContainer: {
    width: '100%'
  },
  innerEventListContent: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    padding: 15,
    width: '85%',
    paddingTop: 0,
  },
  eventListContent: {
    //display: 'flex',
    flexDirection: 'column',
    // border: '2px solid ' + AppTheme.secondaryColor,
    marginBottom: 20,
    marginTop: 10,
    cursor: 'pointer',
    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)'

    //minHeight: 125,
    // ':hover': {
    //   border: '2px solid #5B4EF7',
    // },
  },
  eventListContentSelected: {
    border: '2px solid '+AppTheme.primaryBase
  },
  eventListStackContent: {
    //display: 'flex',
    flexDirection: 'column',
    // border: '2px solid ' + AppTheme.primaryBase,
    marginBottom: 20,
    marginTop: 10,
    cursor: 'pointer',
    //minHeight: 125,
    ':hover': {
      // border: '2px solid #5B4EF7',
    },
    // boxShadow: '0 0px 0px rgba(0,0,0,0.15),4px 4px 1px ' + AppTheme.primaryBackground + ',7px 7px 0px -1px rgba(0,0,0,0.3), 10px 10px 1px ' + AppTheme.primaryBackground
    boxShadow:"rgb(0 0 0 / 21%) 0px 0px 0px, rgb(0 0 0 / 31%) 4px 4px 1px, rgb(0 0 0 / 26%) 7px 7px 0px -1px, rgb(0 0 0 / 20%) 10px 10px 1px !important"
  },
  eventListImageConatiner: {
    width: '100%',
    /*height: 50,*/
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: AppTheme.black
  },
  eventImg: {
    width: '100%',
    height:'6rem !important',
    objectFit:'scale-down !important',
    imageRendering: 'pixelated'
  },
  eventListTitleConatiner: {
    // backgroundColor: AppTheme.primaryBackground,
    // color: AppTheme.white,
    padding: 5,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    whiteSpace: 'nowrap'
  },
  eventListTitleConatinerCust: {
    padding: 5,
    overflow: 'hidden'
  },
  eventListTitle: {
    margin: 0,
    fontSize: 13,
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-all'
  },
  eventListTitleCust: {
    fontSize: 10,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  eventListDateContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 5,
  },
  eventListDate: {
    flex: 1,
    margin: 0,
    fontSize: 11,
    opacity: 0.6,
    whiteSpace: 'pre-wrap'
  },
  eventListDateStr: {
    flex: 1,
    margin: 0,
    marginTop: 5,
    fontSize: 11,
    opacity: 0.6,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  eventListDateCust: {
    fontSize: 9,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  eventImageLoading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
    color: AppTheme.white,
    opacity: 0.6
  },
  innerEventListContainer: {
    // width: window.innerWidth * 0.9 * 0.14, //TODO
    width: innerEventListContainerWidth, // TODO
    // maxHeight: 768,
    // height: '100vh',
    height: '95%',
    marginBottom: 10,
    padding: 5,
    paddingTop: 30,
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: AppTheme.primaryColor,
    alignItems: 'center',
    '@media(max-width: 768px)': {
      display: 'none'
    },
    '@media(min-width: 1750px)': {
      height: '100vh',
    }
  },
  innerEventListContainerRelativeOpen: {
    // width: window.innerWidth * 0.9 * 0.14, //TODO
    width: innerEventListContainerWidth, //TODO
    //maxHeight: 900,
    height: '100vh',
    //paddingBottom: 30,
    padding: 5,
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'column',
    borderRight: '1px solid #374056',
    opacity: .1,
    zIndex: 1,
    backgroundColor: AppTheme.primaryColor,
    pointerEvents: 'none',
    '@media(max-width: 768px)': {
      display: 'none'
    }
  },
  eventItemOuterContainer: {
    display: 'flex',
    // width: window.innerWidth * .9 * .84, //TODO
    width: eventItemOuterContainerWidth, //TODO
    alignItems: 'flex-start',
    '@media(max-width: 768px)': {
      flexDirection: 'column',
      width: '100%',
      alignItems: 'center',
    },
    height:"100%",

    // overflow:"auto"
  },
  eventItemOuterContainerSpec: {
    // width: window.innerWidth
    width: '100%'
  },
  eventItemHeaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px',
    // height: '100vh',
    paddingBottom: 0,
    marginBottom: 10,
    // width: window.innerWidth * .9 * .84 * .64, //TODO
    width: eventItemHeaderContainerWidth, //TODO
    height:"95%",
    position: 'relative',
    marginLeft: 10,
    marginRight: 10,
    backgroundColor: AppTheme.primaryColor,
    '@media(max-width: 768px)': {
      width: '93%',
      padding: 10,
      margin: 0,
      height: 'auto',
    },
    '@media(min-width:768px) and  (max-width: 1024px)': {
      width: '97%',
      padding: 10,
      margin: 0,
    }

  },
  eventItemHeaderContainerSpec: {
    marginLeft: 0
  },
  eventItemFeatureContainer: {
    // width: window.innerWidth * .9 * .84 * .3, //TODO
    width: eventItemFeatureContainerWidth, //TODO
    height:"95%",
    backgroundColor: AppTheme.primaryColor,
    padding: 5,
    paddingTop: 30,
    paddingBottom: 0,
    height: '95%',
    marginBottom: 10,
    //marginTop: 103,
    '@media(max-width: 768px)': {
      width: '100%',
      marginTop: 20,
      marginBottom: 30,
      padding: 0,
      paddingTop: 15,
      paddingBottom: 15,
      height: 'auto',
    }

  },
  eventItemFeatureContainerRelativeOpen: {
    // width: window.innerWidth * .9 * .84 * .3, //TODO
    width: eventItemFeatureContainerWidth, //TODO
    opacity: .1,
    zIndex: 1,
    marginTop: 103,
    pointerEvents: 'none',
    backgroundColor: AppTheme.primaryColor,
    padding: 5,
    paddingTop: 30,
    //paddingBottom: 30,
  },
  eventItemHeaderContent: {
    display: 'flex',
    position: 'relative'
  },
  eventHeaderOuterContent: {
    display: 'flex',
  },
  eventItemHeaderInnerContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  eventItemHeaderSearchInnerContent: {
    display: 'none',
    '@media(max-width: 768px)': {
      display: 'flex',
      border: '1px solid #354058',
      borderRadius: 4,
      width: '100%',
      marginBottom: 10
    }
  },
  eventDateRangeContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 10,
    paddingRight: 10
  },
  eventListContainer: {
    overflow: 'scroll'
  },
  eventItemTime: {
    // color: AppTheme.white,
    color: "#000",
    // opacity: 0.3,
    margin: 0,
    fontSize: 11,
    cursor: 'default',
  },
  eventItemDate: {
    // color: AppTheme.white,
    opacity: 0.7,
    fontSize: 10,
    cursor: 'default',
    margin: '2px 0px 2px 0px'
  },
  eventItemDateDivider: {
    textAlign: 'center',
    // color: AppTheme.white,
    opacity: 0.8,
    flex: 1,
    margin: '2px 0px 2px 0px',
    fontSize: 9,
  },
  timeContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  eventItemTitle: {
    // color: AppTheme.white,
    color:"#000",

    // opacity: 0.7,
    fontSize: 20,
    margin: 0,
    marginTop: 8,
    cursor: 'default'
  },
  eventItemInnerContent: {
    display: 'flex',
    cursor: 'default',
    alignItems: 'flex-start',
    '@media (max-width: 767px)': {
      flexDirection: 'column'
    }
  },
  eventItemOuterTagContent: {
    //flex: 0.3,
    marginTop: 10
  },
  eventItemInnerTagContent: {
    display: 'flex',
  },
  eventItemType: {
    border: '1px solid #354058',
    padding: 5,
    fontSize: AppTheme.fontSizeContent,
    // color: '#C7C9CB',
    borderRadius: 4,
    marginRight: 8,
    cursor: 'default',
    //maxWidth: 'fit-content'
  },
  eventItemTypePriority: {
    border: '1px solid #F5A623',
    borderRadius: 4,
  },
  eventLiveFeed: {
    position: 'absolute',
    top: 20,
    right: 20,
    display: 'flex',
    cursor: 'pointer',
    '@media (max-width: 768px)': {
      top: 30,
      right: 0,
    },
    '@media(min-width:768px) and  (max-width: 1024px)': {
      top: 50,
    }

  },
  eventRelativeLiveFeed: {
    top: 120,
    right: 25,
    zIndex: 1
  },
  eventLiveFeedContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    cursor: 'pointer',
  },
  eventLiveFeedContent: {
    border: '1px solid #354058',
    borderRadius: 4,
    padding: '10px 15px',
    fontSize: AppTheme.fontSizeContent,
    fontWeight: 'bold',
    opacity: 0.6,
    color: AppTheme.white,
    '@media (max-width: 767px)': {
      fontSize: 11,
      padding: '10px 10px',
    }
  },
  eventImageContent: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: AppTheme.black,
    alignItems: 'center',
    margin: '20px 0px'
  },
  relativeSettings: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    //marginLeft: 25
  },
  relativeSettingsWithMargin: {
    display: 'flex',
    width: '100%',
    marginLeft: 26,
  },
  editImageDialog: {
    margin: 0
  },
  eventImageConatiner: {
    marginTop: 10
  },
  eventImage: {
    maxWidth: '100%',
  },
  eventImageSpecContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  eventImageSpec: {
    maxWidth: '100%',
    //maxHeight: '100%',
    maxHeight: 400,
    width: 'auto',
    //height: '400px',
    objectFit: 'contain'
  },
  // eventImageSpec: {
  //   width: '100%',
  //   maxHeight: '400px',
  //   '@media (max-width: 767px)': {
  //     maxHeight: '200px',
  //   }
  // },
  filterIcon: {
    //marginRight: 8,
  },
  eventRelativeImage: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    outline: 'unset',
  },
  eventRelativeImageLess: {
    justifyContent: 'flex-start',
    width: 130
  },
  highlightRelative: {
    // border: '1px solid #5B4EF7'
    border: '2px solid' + AppTheme.primaryBase,

  },
  eventRelativeImageContent: {
    //maxWidth: 130,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    // backgroundColor: AppTheme.primaryBackground,
    border: '2px solid' + AppTheme.primaryBase,
  },
  relativeImageContent: {
    maxWidth: '100%',
    imageRendering:'pixelated'
  },
  eventControlContent: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor:"#eee"
  },
  eventControlContentRelativeOpen: {
    display: 'flex',
    justifyContent: 'center',
    // opacity: .1,
    zIndex: 1,
    // backgroundColor: AppTheme.secondaryColor,
    color:"#000",
    pointerEvents: 'none'
  },
  eventInnerControlContent: {
    display: 'flex',
  },
  colorWhite:{
    color:"#ffff"
  },
  eventInnerControl: {
    display: 'flex',
    flexDirection: 'column',
    width: 43,
    marginRight: 40,
    alignItems: 'center',
    '@media(max-width: 768px)': {
      marginRight: 20,
      marginLeft: 20
    }
  },
  eventInnerControlImgConatinerLast: {
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
    width: 30,
    height: 30,
    cursor: 'default',
    pointerEvents: 'none',
    '@media(max-width: 768px)': {
      width: 20,
      height: 20,
    }
  },
  eventInnerControlImgConatiner: {
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
    width: 30,
    height: 30,
    cursor: 'pointer',
    ':hover': {
    },
    '@media(max-width: 768px)': {
      width: 20,
      height: 20,
    }
  },
  eventPlayerIcon: {
    color: "#616161 !important",
    opacity: 0.6,
    fontSize: 40,
    '@media(max-width: 768px)': {
      fontSize: 30,
    }
  },
  controlImg: {
    width: '100%',
    ':hover': {
      cursor: 'pointer'
    }
  },
  eventInnerControlImgTitle: {
    fontSize: 10,
    // opacity: .3,
    color: "#616161 !important",
    textAlign: 'center',
    marginTop: 6,
    cursor: 'default'
  },
  eventItemHeaderConatinerRelativeOpen: {
    // opacity: .1,
    zIndex: 1,
    // backgroundColor: AppTheme.secondaryColor,
    pointerEvents: 'none',
  },
  eventItemHeaderConatinerRelativeClose: {
    display: 'flex',
    flexDirection: 'column'
  },
  relatedImageHeaderContainer: {
    display: 'flex',
    padding: '30px 0px 00px 0px',
    cursor: 'pointer'
  },
  mb2r: {
    marginBottom:'2rem'
  },
  mt2r: {
    marginTop:'2rem'
  },
  relatedImageTitile: {
    color: '#000',
    fontSize: AppTheme.fontSizeTitle,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    cursor: 'deafult',
    marginLeft: 26
  },
  relatedImageSelectionContent: {
    width: 24,
    height: 24,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: '#1D2331'
    }
  },
  relatedImageSelectionLess: {
    pointerEvents: 'none',
    ':hover': {
      backgroundColor: AppTheme.secondaryColor
    }
  },
  videoContainer: {
    textAlign: 'center',
    justifyContent: 'center',
    height: '8rem',
    width: '15rem',

    '@media (min-width: 1900px)': {
      height: '12.5rem',
      width: '24.5rem'
    },
    '@media (min-width: 769px) and (max-width: 998px)': {
      height: '6rem',
      width: '11.5rem'
    },
  },
  eventEditOuterContainer: {
    display: 'flex',
    marginLeft: 25,
    marginRight: 25,
  },
  eventEditContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  eventEditContainerStyle: {
    marginTop: 0,
    flex: 1,
    justifyContent: 'flex-end'
  },
  tagContainerHeight: {
    maxHeight: 100,
    // overflow: 'scroll'
  },
  eventEditImgContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: 10
  },
  eventEditImg: {
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    border: '1px solid #354058',
    borderRadius: 4,
    padding: 4,
    // opacity: 0.6,
  },
  openEventIcon: {
    // color: AppTheme.white,
    // opacity: 0.6,
    color:AppTheme.primaryBase,
    fontSize: 26
  },
  eventEdit: {
    margin: 0,
    fontSize: AppTheme.fontSizeContent,
    '@media(max-width: 768px)': {
      fontSize: 11
    }
  },
  dialog: {
    minWidth: 600,
    '@media(max-width: 768px)': {
      minWidth: '-webkit-fill-available',
      margin: 5
    }
  },
  scrollPaper: {
    justifyContent: 'center',
    '@media(max-width: 768px)': {
      overflow: 'scroll',
      justifyContent: 'flex-start'
    }
  },
  dialogTitle: {
    backgroundColor: AppTheme.white,
    padding: 20,
  },
  dialogTitleContainer: {
    display: 'flex'
  },
  dialogTitleContent: {
    margin: 0,
    fontSize: AppTheme.fontSizeTitle,
    cursor: 'default',
    fontWeight: 600,
  },
  closeIconContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    cursor: 'pointer'
  },
  dialogContent: {
    padding: 20,
    paddingBottom: 0,
    backgroundColor: AppTheme.primaryBackground,
  },
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  statAdjustInfo: {
    marginBottom: 25,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  statAdjustContainerTitle: {
    margin: 0,
    fontWeight: 'bold',
    fontSize: 15,
    color: AppTheme.white,
    cursor: 'default',
    opacity: .7,
    flex: 0.5
  },
  statAdjustVal: {
    margin: 0,
    fontSize: 15,
    color: AppTheme.white,
    fontWeight: 'bold',
  },
  dialogActions: {
    alignItems: 'flex-end',
    padding: 20,
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: AppTheme.primaryBackground,
  },
  dialogActionsSpec: {
    alignItems: 'center'
  },
  actionButton: {
    backgroundColor: ColourPalette.color3,
    color: AppTheme.white,
    fontWeight: 'bolder',
    margin: 0,
    fontSize: AppTheme.fontSizeTitle,
    textTransform: 'unset',
    marginLeft: 15,
    padding: '0px 16px'
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative'
  },
  iconContainerSpec: {
    '@media (max-width: 768px)': {
      display: 'none'
    }
  },
  iconContainerNext: {
    backgroundColor: AppTheme.primaryBase,
    color: AppTheme.white,
    width: 40,
    height: 40,
    borderRadius: '50%',
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    right: -45,
    cursor: 'pointer',
    '@media(min-width:768px) and  (max-width: 1024px)': {
      right: -35,
    }
  },
  iconContainerPrev: {
    backgroundColor: AppTheme.primaryBase,
    color: AppTheme.white,
    width: 40,
    height: 40,
    borderRadius: '50%',
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    left: -45,
    cursor: 'pointer',
    '@media(min-width:768px) and  (max-width: 1024px)': {
      right: -35,
    }
  },
  imageSliderContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: '20px 0px',
  },
  slider: {
    display: 'flex',
    marginTop: 10,
  },
  editDialog: {
    minWidth: 465,
    '@media (max-width: 767px)': {
      minWidth: 'unset',
      margin: 15
    }
  },
  editDialogTitle: {
    color: AppTheme.primaryBackground,
    margin: 0,
    fontSize: AppTheme.fontSizeTitle,
    cursor: 'default'
  },
  editDialogContent: {
    // backgroundColor: AppTheme.primaryBackground,
    border:"1px solid",
    padding: '20px 40px',
    '@media (max-width: 767px)': {
      padding: '20px 10px',
    }
  },
  editInputCss: {
    flex: 1,
    color: AppTheme.white,
    margin: '20px 0px 0px 0px'
  },
  editIncidentTitle: {
    opacity: 0.6,
    // color: AppTheme.white,
    fontSize: AppTheme.fontSizeContent,
    margin: '20px 0px 10px 0px',
    cursor: 'default',
    '@media (max-width: 767px)': {
      marginBottom: 0
    }
  },
  editIncidentTag: {
    marginBottom: 0,
  },
  editIncidentType: {
    borderRadius: 4,
    // backgroundColor: ColourPalette.color1,
    backgroundColor: ColourPalette.color1,

    padding: 5,
    maxWidth: 'fit-content'
  },
  prioritySize: {
    fontSize: 12
  },
  editAlert: {
    display: 'flex',
    flexWrap: 'wrap',
    
  },
  tagsFont: {
    fontSize: 11.5
  },
  editAlertContainer: {
    display: 'flex',
    maxWidth: 'fit-content',
    marginRight: 15,
    cursor: 'pointer',
    '@media (max-width: 767px)': {
      marginTop: 10
    },
    border:"1px solid"
  },
  editAlertDotContainer: {
    padding: '12px 8px',
    marginRight: 1,
    backgroundColor: AppTheme.primaryColor,
    display: 'flex',
    alignItems: 'center',
  },
  editAlertDotContent: {
    borderRadius: '50%',
    width: 8,
    height: 8
  },
  editAlertLabel: {
    // color: AppTheme.white,
    fontSize: 11,
    fontWeight: 'bold',
    padding: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1
  },
  saveButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 20,
    marginRight: 10
  },
  saveButtonOuterContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  saveButton: {
    padding: '3px 16px',
    backgroundColor: ColourPalette.color3,
    color: AppTheme.white,
    '@media(max-width: 767px)': {
      padding: '3px 10px',
      fontSize: 12
    }
  },
  outerHeaderStatCard: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  outerHeaderStatCardSpec: {
    marginBottom: 20,
    // transition: 'all 10s ease-out'
  },
  statContainer: {
    width: '100%',
    // backgroundColor: '#11161D',
    // border: '1px solid #646871',
    borderRadius: 8,
    padding: 20,
  },
  statContainerSpec: {
    '@media(max-width: 767px)': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  statContainerTitle: {
    display: 'flex',
    marginBottom: 15,
    width: '100%',
    alignItems: 'center'
  },
  statTitleImg: {
    width: 24,
    height: 24
  },
  barChartTitleImg: {
    width: 34,
    height: 34
  },
  barChartIcon: {
    // color: ColourPalette.color3,
    opacity: 0.6,
    fontSize: 40
  },
  downloadIcon: {
    paddingLeft: 10,
    paddingRight: 10,
    cursor: 'pointer',
    // color: ColourPalette.color1,
    color:"rgb(255, 255, 255) !important",
    '@media (max-width: 767px)': {
      paddingLeft: 5,
      paddingRight: 5,
    }
  },
  statTitle: {
    margin: 0,
    fontSize: 20,
    // color: AppTheme.white,
    marginLeft: 8,
    // opacity: .6,
    alignSelf: 'center'
  },

  paginationContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor:"#eee",
    margin:"1rem 0rem"
  },
  refreshIconContainer: {
    padding: 8,
    ':hover': {
      // backgroundColor: AppTheme.secondaryColor,
    }
  },
  refreshIcon: {
    borderRadius: '50%',
    cursor: 'pointer',
    opacity: 0.5,
    // color: AppTheme.white,
    color: "#616161 !important",

    ':hover': {
      backgroundColor: '#1D2331',
    }
  },
  pageNumber: {
    // opacity: 0.7,
    color: "#616161 !important",
    fontSize: 15
  },
  eventPriorityContainer: {
    display: 'flex',
    // color:"#fff"
  },
  reportedPriorityContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },

  statAdjustIcon: {
    height: 18,
    width: 18,
    fontSize: 18,
    minHeight: 18,
    minWidth: 18,
  },
  statAdjustIconContainer: {
    height: 26,
    width: 26,
    fontSize: 26,
    minHeight: 26,
    minWidth: 26,
  },
  emptyEventContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // color: 'white',
    flex: 1,
  },
  emptyEventContainer2:{
    position:"relative",
    // bottom:"10%"
  },
  tagsInnerContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    // color:"#fff !important"
  },
  tagsOuterContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  tagContainer: {
    border: '1px solid #354058',
    marginTop: 10,
    marginRight: 8,
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tagContainerDetail: {
    border: '1px solid #6c6c6c',
    borderRadius: 5,
  },
  tagKey: {
    // backgroundColor: ColourPalette.color1,
    margin: 0,
    // color: AppTheme.primaryBackground,
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    padding: 5,
    borderRadius: '4px 0px 0px 4px',
    cursor: 'default'
  },
  tagValue: {
    margin: 0,
    // color: '#C7C9CB',
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    padding: 4,
    cursor: 'default'
  },
  progress: {
    // color: AppTheme.white,
    color: AppTheme.primaryBase,
    margin: '20px 0px'
  },
  searchOuterTextFieldMobile: {
    display: 'none',
    '@media(max-width: 768px)': {
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    }
  },
  searchTextFieldMobile: {
    display: 'none',
    '@media(max-width: 768px)': {
      display: 'block',
      border: '1px solid #354058',
      borderRadius: 4,
      width: '100%'
    }
  },
  multiPickerForm: {
    '@media(max-width: 768px)': {
      flex: 1
    }
  },
  headerMemberOuterContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: '2rem',
    //opacity: 0.8
    '@media(max-width: 768px)': {
      marginRight: 0,
      justifyContent: 'space-between',
      flexDirection: 'column',
      alignItems: 'unset'
    }
  },
  withOpacity: {
    opacity: 0.1
  },
  statsUpIcon: {
    // color: AppTheme.white,
    opacity: 0.6,
    fontSize: 35,
    '@media(max-width: 768px)': {
      fontSize: 25,
    },
    ':hover': {
      cursor: 'pointer'
    }
  },
  statCollapse: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft:"1rem",

  },
  gridMainContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  gridContainer: {
    margin: '5px 0px 5px 0px'
  },
  gridContainerVariant: {
    margin: '5px 0px 5px 0px',
    width: '100%'
  },
  gridImageContainer: {
    margin: '5px 0px 5px 0px',
    width: '100%',
    padding: '0px 20px'
  },
  errMsg: {
    color: AppTheme.white,
    opacity: 0.7,
    margin: '0px'
  },
  closeImageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    marginTop: 20
  },
  withBorder: {
    border: '1px solid ' + AppTheme.primaryBase,
  },
  noRightMargin: {
    marginRight: 0
  },
  fullWidth: {
    width: '100%'
  },
  reportBackContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: 10,
  },
  closeIcon: {
    color: 'white',
    alignSelf: 'center',
    cursor: 'pointer'
  },
  closeIconDate: {
    opacity: 0.6,
    // color: 'white',
    alignSelf: 'flex-end',
    cursor: 'pointer'
  },
  noItemsToShow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  noItemsToShowText: {
    color: AppTheme.white,
  },
  reviewCard:{
    color: 'rgb(0, 0, 0) !important',
    opacity: '0.7 !important',
    fontSize: '18px !important',
    cursor: 'default !important',
    borderRadius: '4px',
  },
  reviewInputCard: {
    color: 'rgb(255, 255, 255) !important',
    opacity: '0.7 !important',
    fontSize: '18px !important',
    cursor: 'default !important',
    backgroundColor: 'rgb(29, 35, 49)',
    borderRadius: '4px',
  },
  brdrLight: {
    border: '1px solid rgb(53, 64, 88) !important',
    borderRadius: '4px !important'
  },
  reviewStatusRadio: {
    borderRadius: '4px', 
    color: AppTheme.primaryBase,
  },
  textForReview:{
    fontSize: '14px !important',
  },
  progressContainer: {
    minHeight: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  downloadImage: {
    paddingLeft: 10,
    paddingRight: 10,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginTop: 5,
    '@media (max-width: 767px)': {
      paddingLeft: 5,
      paddingRight: 5,
    },
    borderRadius: '4px',
    boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
  },
  downloadContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor:AppTheme.primaryBase,
    borderRadius: '4px',
  },
  inputLabelRoot: {
    zIndex: 1,
    marginLeft: 8,
    opacity: .5,
    // color: AppTheme.white,
    pointerEvents: 'none'
  },
  inputLabelFocused: {
    color: AppTheme.white
  },
})